import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GTMEvents from '@app/config/gtmEvents';
import { TrackerContext } from './context';
import { usePublicEnv } from '@app/contexts/PublicEnv';
import { useIntl } from 'react-intl';
import { Constants } from '@app/config/constants';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import isEmpty from 'lodash/isEmpty';

const TrackerProvider = memo(function TrackerProvider({ children, tracker, config }) {
  const { userId } = usePublicEnv();
  const { formatMessage } = useIntl();
  const [trackerInstance, setTracker] = useState(tracker);

  const loadTracker = useCallback(async () => {
    try {
      await initializeTracker(config);
      setTracker({ track });
    } catch (e) {
      return null;
    }
  }, [config]);

  useEffect(() => {
    if (!isEmpty(trackerInstance)) {
      return;
    }

    loadTracker();
  }, [trackerInstance, loadTracker]);

  /**
   * pageLoaded
   *
   * @description Function to fire an event to gtm to track
   * when user load detail
   * @param {string} origin - Origin request
   * @param {string} orderId - Order id
   * @param {string} status - Order status example: (DELIVERED | CANCELLED)
   * @param {boolean} compensation - Order cancelled with compensation
   */
  const pageLoaded = (origin, orderId, status, compensation) => {
    trackerInstance?.track(GTMEvents.PageLoad, {
      userId,
      orderId,
      origin,
      orderState: status,
      hasCompensation: compensation,
    });
  };
  /**
   * repeatClicked
   *
   * @description Function to fire event to gtm to track
   * when user make click over repeat order button
   * @param {string} origin - Origin request
   * @param {string} status - Order status example: (DELIVERED | CANCELLED)
   */
  const repeatClicked = (orderId, status) => {
    trackerInstance?.track(GTMEvents.RepeatClicked, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      orderState: status,
    });
  };
  /**
   * rateClicked
   *
   * @description Function to fire event to gtm to track
   * when user make click over repeat order button
   * @param {string} orderId - Order id
   */
  const rateClicked = (orderId) => {
    trackerInstance?.track(GTMEvents.RateClicked, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      position: 0,
    });
  };
  /**
   * couponClicked
   *
   * @description Function to fire event to gtm track when user click over claim cuopon
   * @param {string} origin - Origin request
   */
  const couponClicked = (orderId, status) => {
    trackerInstance?.track(GTMEvents.UseCouponClicked, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      orderState: status,
    });
  };
  /**
   * helpClicked
   *
   * @description Function to fire event to gtm track when user click over partner
   * @param {string} origin - Origin request
   * @param {string} status - Order status, example: (DELIVERED | CANCELLED)
   * @param {string} action - Action of button, example (repeat_order)
   */
  const helpClicked = (orderId, status) => {
    trackerInstance?.track(GTMEvents.HelpClicked, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      orderState: status,
    });
  };
  /**
   * refundDetailClicked
   *
   * @description Function to fire event to gtm track when user click refund detail action
   * @param {string} origin - Origin request
   * @param {string} status - Order status, example: (DELIVERED | CANCELLED)
   * @param {string} action - Action of button, example (repeat_order)
   */
  const refundDetailClicked = (orderId, status) => {
    trackerInstance?.track(GTMEvents.RefundDetailClicked, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      orderState: status,
    });
  };

  const outOfStockShow = (orderId, status) => {
    trackerInstance?.track(GTMEvents.SoldOutShown, {
      origin: Constants.ORIGIN,
      userId,
      orderId,
      orderState: status,
    });
  };

  const value = useMemo(
    () => ({
      gtmTrack: trackerInstance?.track,
      pageLoaded,
      repeatClicked,
      rateClicked,
      couponClicked,
      helpClicked,
      outOfStockShow,
      refundDetailClicked,
    }),
    [trackerInstance],
  );

  return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
});

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tracker: PropTypes.object,
  config: PropTypes.object,
};

export { TrackerProvider };
