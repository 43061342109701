import { useIntl } from 'react-intl';

type CountriesHourType = { [key: string]: { hour12: boolean; suffix: string } };

export const useDate = (country: string) => {
  const { formatDateToParts } = useIntl();
  const countries24H: CountriesHourType = {
    ar: { hour12: false, suffix: 'hs' },
    uy: { hour12: false, suffix: 'hs' },
    cl: { hour12: false, suffix: 'hrs' },
    py: { hour12: false, suffix: 'hs' },
  };
  const finalCountry = country ? country.toLowerCase() : '';
  /**
   * hourRangeDelivery
   *
   * @param {string | Date } from - from date
   * @param {string | Date }to - to date
   * @returns {string}
   * @example 10:00 - 13:00 hs
   */
  const hourRangeDelivery = (from: string | Date, to: string | Date): string => {
    const fromRange = formatDateToParts(from, {
      minute: '2-digit',
      hour: '2-digit',
      hour12: countries24H[finalCountry] ? countries24H[finalCountry].hour12 : true,
    });
    const toRange = formatDateToParts(to, {
      minute: '2-digit',
      hour: '2-digit',
      hour12: countries24H[finalCountry] ? countries24H[finalCountry].hour12 : true,
    });

    let finalFormat = `${fromRange[0].value}${fromRange[1].value}${fromRange[2].value} - ${toRange[0].value}${toRange[1].value}${toRange[2].value}`;
    finalFormat += ` ${
      countries24H[finalCountry]
        ? countries24H[finalCountry].suffix
        : toRange[4].value.replace(/\.\s/g, '').replace('.', '')
    }`;
    return finalFormat;
  };
  /**
   * dateHourWithoutYear
   * @param {string | Date } date - date
   * @returns {string}
   * @example vie 5 de nov, 11:23 hs
   */
  const dateHourWithoutYear = (date: string | Date): string => {
    const datef = formatDateToParts(date, {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: countries24H[finalCountry] ? countries24H[finalCountry].hour12 : true,
    });

    let finalFormat = `${datef[0].value} ${datef[2].value} de ${datef[4].value.replace('.', '')}, ${
      datef[8].value
    }:${datef[10].value}`;
    finalFormat += ` ${
      countries24H[finalCountry]
        ? countries24H[finalCountry].suffix
        : datef[12].value.replace(/\.\s/g, '').replace('.', '')
    }`;

    return finalFormat;
  };

  /**
   * dateWithoutYear
   * @param {string | Date } date - date
   * @returns {string}
   * @example vie 5 de nov
   */
  const dateWithoutYear = (date: string | Date): string => {
    const datef = formatDateToParts(date, {
      year: 'numeric',
      weekday: 'short',
      month: 'short',
      day: '2-digit',
      hour12: countries24H[finalCountry] ? countries24H[finalCountry].hour12 : true,
    });

    return `${datef[0].value} ${datef[2].value} de ${datef[4].value.replace('.', '')}`;
  };

  /**
   * hourDelivery
   * @param {string | Date } date - date
   * @returns {string}
   * @example vie 5 de nov
   */
  const hourDelivery = (date: string | Date): string => {
    const datef = formatDateToParts(date, {
      minute: '2-digit',
      hour: '2-digit',
      hour12: countries24H[finalCountry] ? countries24H[finalCountry].hour12 : true,
    });

    let finalFormat = `${datef[0].value}${datef[1].value}${datef[2].value}`;
    finalFormat += ` ${
      countries24H[finalCountry]
        ? countries24H[finalCountry].suffix
        : datef[4].value.replace(/\.\s/g, '').replace('.', '')
    }`;

    return finalFormat;
  };

  return {
    hourRangeDelivery,
    dateHourWithoutYear,
    hourDelivery,
    dateWithoutYear,
  };
};
