import { Detail } from '@shared/models/Detail';
import { OrderStatus } from '@shared/config';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

export class DetailProxy {
  private data: Detail;
  constructor(data: Detail) {
    this.data = data;
  }

  public hasRefund() {
    return !isEmpty(this.data?.refund);
  }

  public hasCompensation() {
    return !isEmpty(this.data?.compensation);
  }

  public hasCompensationandOrRefund() {
    return this.hasRefund() || this.hasCompensation();
  }

  public hasExtraPayments() {
    const status = this.data?.order?.status ? this.data.order.status.toUpperCase() : '';
    return (
      isArray(this.data.collectionsRequests) &&
      this.data.collectionsRequests.length > 0 &&
      status !== OrderStatus.REJECTED
    );
  }

  public hasStatus() {
    return this.data?.order?.status ? true : false;
  }

  public hasAddress() {
    return this.data.shipping && this.data?.shipping?.addresses?.length > 0;
  }

  public hasPayments() {
    return this.data.paymentMethod && this.data.paymentMethod.length > 0;
  }

  public hasProducts() {
    return this.data?.order?.items && this.data?.order?.items.length > 0;
  }

  public hasSummary() {
    return this.data?.summary && this.data.summary.concepts.length > 0;
  }

  public hasRemedies() {
    return (
      this.data?.order?.status === OrderStatus.REJECTED &&
      !isEmpty(this.data?.cancellationInformation?.orderRejectionInternalReason) &&
      this.data.cancellationInformation &&
      this.data?.cancellationInformation?.remedies?.actions.length > 0
    );
  }
}
