export enum Constants {
  FLOW_PARAM = 'flow',
  SAFE_PARAM = 'hasSafe',
  BANK_TRANSFER_DAYS = 7,
  UPDATE_AMOUNT_IMAGE = 'https://pedidosya.dhmedia.io/image/pedidosya/order-management/updateAmount.jpg',
  VOID_IMAGE = 'https://pedidosya.dhmedia.io/image/pedidosya/order-management/canceledPayment.jpg',
  CREDIT_IMAGE = 'https://pedidosya.dhmedia.io/image/pedidosya/order-management/refundedPayment.jpg',
  EXTRAPAYMENT_IMAGE = 'https://images.dhmedia.io/image/pedidosya/order-management/extraPayment.jpg',
  CREDIT_DAYS = 7,
  VOID_DAYS = 7,
  NOTCH_MIN_RESOLUTION = 812,
  ORDER_DELIVERED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagEnabled2CloseSmallOn.svg',
  ORDER_SCHEDULED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagEnabled2CloseSmallTime.svg',
  ORDER_REJECTED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagDisabled2CloseSmallOn.svg',
  ORDER_PENDING_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/foodBagEnabled2CloseSmall.svg',
  ORDER_COURIER_DELIVERED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/backpackEnabled1PositiveSmall.svg',
  ORDER_COURIER_SCHEDULED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/backpackEnabled1TimerSmall.svg.svg',
  ORDER_COURIER_REJECTED_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/backpackDisabled1Small.svg',
  ORDER_COURIER_PENDING_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/fenix/illustrations/backpackEnabled1Small.svg',
  VOUCHER_ILUSTRATION = 'https://pedidosya.dhmedia.io/image/pedidosya/order-management/voucher_fenix.jpg',
}
