import type { Method } from '@shared/models';
import { PaymentMethods } from '@shared/config/PaymentsMethod';

export class RefundTypeProxy {
  private data: Method;
  constructor(data: Method) {
    this.data = data;
  }

  isWallet(): boolean {
    return this.data.type.toUpperCase() === PaymentMethods.WALLET;
  }

  isVoucher(): boolean {
    return this.data.type.toUpperCase() === PaymentMethods.VOUCHER;
  }

  isCard(): boolean {
    return this.data.type.toUpperCase() === PaymentMethods.CARD;
  }

  isBankTransfer(): boolean {
    return this.data.type.toUpperCase() === PaymentMethods.BANK_TRANSFER;
  }

  isCash(): boolean {
    return this.data.type.toUpperCase() === PaymentMethods.CASH;
  }
}
