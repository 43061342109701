import React from 'react';
import PropTypes from 'prop-types';
import QueryClient from '@app/utils/query_client';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/contexts/PublicEnv';
import { createIntlProvider, getMessages } from '@app/contexts/IntlProvider';
import { SentryProvider } from '@app/contexts/SentryProvider';
import { RouterApp } from '@app/components/Router';
import { QueryClientProvider } from 'react-query';
import { LinkProvider } from '@app/contexts/LinkProvider';
import { TrackerProvider } from '@app/contexts/TrackerProvider';
import { GlobalError } from '@app/components/GlobalError';
import { SentrySSR } from '@app/shared/contexts/SentrySSR';
import loadable from '@loadable/component';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const RouterAppV2 = loadable(() => import('@app/detail/presentation/v2/components/Router'), {
  resolveComponent: (components) => components.RouterApp,
  fallback: <PeyaLoader position="center" />,
});

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

const Root = ({ context, messages, name, tracker, trackerConfig, isServer, sentryConfig }) => {
  return (
    <GlobalError>
      <SentrySSR
        sentryDSN={sentryConfig?.dsn}
        environment={context?.environment}
        enabled={sentryConfig?.isEnabled}
        isServer={isServer ? true : false}
      >
        <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
          <SentryProvider isServer={isServer ? true : false}>
            <FenixProvider config={{ revamp: context?.revamp ? true : false }}>
              <>
                <IntlProvider initialMessages={messages}>
                  <QueryClientProvider client={QueryClient}>
                    <TrackerProvider tracker={tracker} config={trackerConfig}>
                      <LinkProvider>
                        {context?.revamp ? <RouterAppV2 /> : <RouterApp />}
                      </LinkProvider>
                    </TrackerProvider>
                  </QueryClientProvider>
                </IntlProvider>
              </>
            </FenixProvider>
          </SentryProvider>
        </PublicEnvProvider>
      </SentrySSR>
    </GlobalError>
  );
};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string,
  trackerConfig: PropTypes.object,
  isServer: PropTypes.bool,
  sentryConfig: PropTypes.object,
};

export default Root;
