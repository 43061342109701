import { getWindow } from '@app/utils/window';

const useDetailRemediesEvents = () => {
  const Listeners = [
    (context) => {
      /**
       * Execute event to trigger deeplink or
       * web event
       */
      const wn = getWindow();
      if (wn) {
        wn.location.href = context?.link;
      }
    },
  ];

  return Listeners;
};

export { useDetailRemediesEvents };
