import { defineMessages } from 'react-intl';

const messages = defineMessages({
  titleError: {
    id: 'OA_orderDetail_fullSCreen_error_global_title',
    defaultMessage: 'Tuvimos un problema',
  },
  subTitleError: {
    id: 'OA_orderDetail_fullSCreen_error_global_description',
    defaultMessage: 'Parece que no podemos atender tu solicitud en este momento.',
  },
  buttonLabelError: {
    id: 'OA_orderDetail_fullSCreen_error_global_button_cta',
    defaultMessage: 'Ir al inicio',
  },
  goToRestaurant: {
    id: 'OA_orderDetail_header_label_goToRestaurant',
    defaultMessage: 'Ver restaurante',
  },
  goToStore: {
    id: 'OA_orderDetail_header_label_goToStore',
    defaultMessage: 'Ver local',
  },
  goToAnyVertical: {
    id: 'OA_orderDetail_header_label_goToAnyVertical',
    defaultMessage: 'Ver {vertical}',
  },
  state_delivered: {
    id: 'OA_orderDetail_status_delivered',
    defaultMessage: 'Entregado',
  },
  state_cancelled: {
    id: 'OA_orderDetail_status_cancelled',
    defaultMessage: 'Cancelado',
  },
  state_scheduled: {
    id: 'OA_orderDetail_status_scheduled',
    defaultMessage: 'Programado',
  },
  state_pending: {
    id: 'OA_orderDetail_status_pending',
    defaultMessage: 'En curso',
  },
  button_repeat_order: {
    id: 'OA_orderDetail_button_repeat_order',
    defaultMessage: 'Repetir pedido',
  },
  button_feedback: {
    id: 'OA_orderDetail_button_feedback',
    defaultMessage: 'Opinar',
  },
  cancel_reasons_store_taken: {
    id: 'OA_orderDetail_cancel_reason_store_taken',
    defaultMessage: 'El local no pudo tomar tu pedido.',
  },
  cancel_reasons_restaurant_taken: {
    id: 'OA_orderDetail_cancel_reason_restaurant_taken',
    defaultMessage: 'El restaurante no pudo tomar tu pedido.',
  },
  cancel_reasons_store_closed: {
    id: 'OA_orderDetail_cancel_reason_store_closed',
    defaultMessage: 'El local estaba cerrado.',
  },
  cancel_reasons_restaurant_closed: {
    id: 'OA_orderDetail_cancel_reason_restaurant_closed',
    defaultMessage: 'El restaurante estaba cerrado.',
  },
  cancel_reasons_any_outside_hours: {
    id: 'OA_orderDetail_cancel_reason_any_outside_hours',
    defaultMessage: 'Debido al horario no pudimos tomar tu pedido.',
  },
  cancel_reasons_any_bad_weather: {
    id: 'OA_orderDetail_cancel_reason_any_bad_weather',
    defaultMessage: 'El mal clima no nos permitió entregar tu pedido.',
  },
  status_datetime_format: {
    id: 'OA_orderDetail_status_datetime_format',
    defaultMessage: '{hour}:{minute} {period}',
  },
  status_date_format: {
    id: 'OA_orderDetail_status_date_format',
    defaultMessage: '{dayName} {day} de {month}, ',
  },
  status_date_year_format: {
    id: 'OA_orderDetail_status_date_year_format',
    defaultMessage: '{day} de {month} {year}',
  },
  status_feedback: {
    id: 'OA_orderDetail_status_feedback',
    defaultMessage: 'Tu opinión sobre este local',
  },
  refund_title: {
    id: 'OA_orderDetail_refund_title',
    defaultMessage: 'Te devolvimos {amount}',
  },
  refund_detail: {
    id: 'OA_orderDetail_refund_detail',
    defaultMessage: 'Encuentra todos los detalles en {email}',
  },
  compensation_title: {
    id: 'OA_orderDetail_compensation_title',
    defaultMessage: 'Te regalamos un cupón de $ 1.500 para que pidas lo que quieras.',
  },
  compensation_title_custom: {
    id: 'OA_orderDetail_compensation_title_custom',
    defaultMessage: 'Te regalamos un cupón de $ 1.500 para tu próximo pedido.',
  },
  compensation_button_useCoupon: {
    id: 'OA_orderDetail_compensation_button_useCoupon',
    defaultMessage: 'Usar mi cupón',
  },
  compensation_button_viewCoupon: {
    id: 'OA_orderDetail_compensation_button_viewCoupon',
    defaultMessage: 'Ver mi cupón',
  },
  global_vertical_restaurant: {
    id: 'OA_orderDetail_global_vertical_restaurant',
    defaultMessage: 'restaurante',
  },
  global_vertical_store: {
    id: 'OA_orderDetail_global_vertical_store',
    defaultMessage: 'local',
  },
  remedies_repeat_order_products_title: {
    id: 'OA_orderDetail_remedies_repeat_order_products_title',
    defaultMessage: '¿Quieres repetir el pedido modificando los productos?',
  },
  remedies_repeat_order_address_title: {
    id: 'OA_orderDetail_remedies_repeat_order_address_title',
    defaultMessage: '¿Quieres repetir el pedido usando otra dirección de entrega?',
  },
  remedies_repeat_order_payment_method_title: {
    id: 'OA_orderDetail_remedies_repeat_order_payment_method_title',
    defaultMessage: '¿Quieres repetir el pedido usando otro medio de pago?',
  },
  remedies_repeat_order_coupon_title: {
    id: 'OA_orderDetail_remedies_repeat_order_coupon_title',
    defaultMessage: '¿Quieres repetir el pedido usando tu cupón?',
  },
  remedies_repeat_order_other_options_title: {
    id: 'OA_orderDetail_remedies_repeat_order_other_options_title',
    defaultMessage: '¿Quieres buscar otras opciones?',
  },
  button_order_local_restaurant: {
    id: 'OA_orderDetail_button_order_local_restaurant',
    defaultMessage: 'Pedir a otro {vertical}',
  },
  global_courier_header_title: {
    id: 'OA_orderDetail_global_courier_header_title',
    defaultMessage: 'Detalle del envio',
  },
  summary_order_free: {
    id: 'OA_orderDetail_summary_order_free',
    defaultMessage: 'Gratis',
  },
  remedies_repeat_order_forget_add_products_title: {
    id: 'OA_orderDetail_remedies_repeat_order_forget_add_products_title',
    defaultMessage: '¿Quieres repetir el pedido agregando productos?',
  },
  remedies_repeat_order_dont_want_products_title: {
    id: 'OA_orderDetail_remedies_repeat_order_dont_want_products_title',
    defaultMessage: '¿Quieres repetir el pedido cambiando productos?',
  },
  remedies_repeat_order_unable_to_find_title: {
    id: 'OA_orderDetail_remedies_repeat_order_unable_to_find_title',
    defaultMessage: '¿Quieres pedir a otro {vertical}?',
  },
  remedies_repeat_order_regret_title: {
    id: 'OA_orderDetail_remedies_repeat_order_regret_title',
    defaultMessage: '¿Quieres repetir el pedido?',
  },
  detail_header_help_center: {
    id: 'OA_orderDetail_detail_header_help_center',
    defaultMessage: 'Ayuda',
  },
  'month.format': {
    id: 'OA_orderDetail_date_month_format',
    defaultMessage: '{day} de {month}',
  },
});

export default messages;
