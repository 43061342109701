export enum ProductStatus {
  DELIVERED = 'DELIVERED',
  MISSING = 'MISSING',
  SOLD_OUT = 'SOLD_OUT',
  REPLACED = 'REPLACED',
  PRICE_CHANGE = 'PRICE_CHANGE',
  WEIGHT_CHANGE = 'WEIGHT_CHANGE',
  QUANTITY_CHANGE = 'QUANTITY_CHANGE',
  REPLACEMENT = 'REPLACEMENT',
}
